.header {
  position: relative;
}

.app-header {
  width: 100%;
  font-size: 50px;
}
.header-text {
  font-family: "Dancing Script", cursive;
  width: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
}

.icon-wrapper {
  position: absolute;
  right: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  color: var(--secondary-text);
  a {
    color: var(--secondary-text);
    &:hover {
      color: var(--primary-text);
    }
  }
}
.icon-holder {
  padding-top: 10px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

@media only screen and (max-width: 870px) {
  .app-header {
    flex-direction: column;
  }
  .icon-wrapper {
    position: relative;
    right: unset;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.header-icon-text {
  font-size: 18px;
  margin-left: 10px;
}

.main-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-text {
  font-family: "Dancing Script", cursive;
  /* border-bottom: 1px solid var(--primary-text);; */
  width: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
}

.app-slogan {
  font-size: 20px;
  height: 30vh;
  border-bottom: 1px solid rgba(128, 128, 128, 0.473);
}
.header-icon-text {
  font-size: 18px;
  margin-left: 10px;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .header-icon-phone {
    font-size: 22px;
  }
}

.translation {
  position: absolute;
  right: none;
  left: 30px;
  top: 14px;
  .MuiTypography-root {
    color: var(--secondary-text);
  }
}

@media only screen and (max-width: 800px) {
  .translation {
    position: absolute;
    left: unset;
    right: 10px;
    top: 0px;
  }
  header .header-text {
    padding-top: 60px;
  }
}
