.body-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    white-space: pre-wrap;
    margin: auto;
}

.body-section.one {
    font-family: 'Chronicle Display A', 'Chronicle Display B';
    width: 40%;
    font-size: 50px;
    padding-top: 42px;
    line-height: 50px;
    color: var(--secondary-text);
    letter-spacing: -0.01em;
    padding: 50px;
    font-weight: 500;
    font-style: normal;
}
.body-section.sec {
    width: 60%;
    display: flex;
    flex-direction: column;
}
.body-section-line {
    display: block;
    border-bottom: 3px solid rgb(202, 145, 145);
    width: 20px;
    height: 10px;
    margin-bottom: 20px;
}
.body-section-p {
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 22px;
    letter-spacing: -0.01em;
    line-height: 30px;
    font-weight: 300;
    font-style: normal;
    color: var(--secondary-text);
}
.body-section-inner-wrap {
    padding: 50px;
}
@media only screen and (max-width: 800px) {
    .body-section {
        flex-direction: column;
    }
    .body-section.one {
        width: 100%;
    }
    .body-section.sec {
        width: 100%;
    }
}
