.socialRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  background-color: #f7f7f7;
  .socialRoot-innerWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  
    &:hover {
      color: var(--primary-text);
    }
    .icon-holder {
      display: flex;
      flex-direction: row;
      align-items: center;
      .socialRoot-svg {
        height: 50px;
        width: 50px;
        svg {
          width: 100%; 
          height: auto;
          fill: var(--secondary-text);
          &.ins {
            // fill: pink;
          }
          &.weixin {
            // fill: green; 
          }
          &.redbook {
            // fill: red !important;
          }
        }
      }
      .socialRoot-id {
        width: 250px;
      }
      text-decoration: none;
      color: var(--secondary-text);
      &:hover {
        svg {
          fill: var(--primary-text);
        }
      }
      .socialRoot-id {
        padding-left: 20px;
        color: var(--secondary-text) !important;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  .socialRoot .socialRoot-innerWrapper{
  }
}