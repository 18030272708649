.mainImgRoot {
  height: 60vh;
  position: relative;

}

.mainImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainImg-textWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: rgba(128, 128, 128, 0.2);
  .mainImg-textInnerWrapper {
    padding-left: 40px;
    padding-bottom: 40px;


    h1.text {
      font-family: "Chronicle Display A", "Chronicle Display B";
      font-weight: 500;
      font-style: normal;
      font-size: 69.5px;
      line-height: 77px;
      margin-top: 20px;
      max-width: 50%;
      color: white;
      background-color: rgba(128, 128, 128, 0.089);
      word-break: keep-all;
    }
    h4 {
      font-family: "Chronicle Display A", "Chronicle Display B";
      font-weight: 300;
      font-style: italic;
      font-size: 17px;
      text-transform: none;
      word-spacing: 1px;
      letter-spacing: 0.25px;
      color: white;
      max-width: 50%;
      background-color: rgba(128, 128, 128, 0.089);
    }
  }
}

@media only screen and (max-width: 800px) {
  .mainImg-textWrapper .mainImg-textInnerWrapper {
    h1.text, h4 {
      max-width: 70%;
    }
  }
}
@media only screen and (min-width: 1600px) {
  .mainImg-textWrapper {
    justify-content: center;
    align-items: center;
    padding-right: 220px;

    h1 {
      font-size: 4vw;
      line-height: 4.5vw;
    }
  }
}
@media only screen and (max-width: 500px) {
}
