.section {
    display: flex;
    flex-direction: row;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    white-space: pre-wrap;
    height: 60vh;
}

.section.one {
    width: 30%;
    // height: 663px;
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateX(-10%);
    transition: all .5s ease-in-out;
}
.section.one.one-animation {
    opacity: 1;
    transform: translateX(0);

}
.section.one .inner-wrap {
    // width: 90%;
}
.section-one-title {
    font-family: 'Chronicle Display A', 'Chronicle Display B';
    font-weight: 500;
    font-style: normal;
    font-size: 47px;
    line-height: 52px;
    // margin-top: 20px;
    color: var(--secondary-text);
    margin-bottom: 23px;
}
.section-one-text {
    display: block;
    font-family: "Gotham SSm A", "Gotham SSm B";
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 14px;
    color: var(--secondary-text);
}
.section.img {
    width: 60%;
    object-fit: cover;
}
.section.video {
}
@media only screen and (min-width: 800px) and (max-width: 1300px) {
    .section {
        height: fit-content;
        clear: both;
        flex-direction: column;
        align-items: center;
        &:after {
            content: "";
            display: block;
            clear: both;
        }
        .section.one {
            width: 80%;
            &:after {
                content: "";
                display: block;
                clear: both;
            }
            .inner-wrap {
                padding-top: 20%;
                padding-bottom: 20%;
            }
        }
        .section.img {
            width: 100%;
        }
    }

}
@media only screen and (max-width: 800px) {
    .section {
        flex-direction: column;
        height: unset;
    }
    .section.one {
        width: 100%;
        padding: 4em 0 5em 0 !important;
        margin: unset !important;
        height: 663px;
    }
    .section.img, .section.video {
        width: 100%;
    }
    .section.video {
        height: 50vh;
    }
    .section.one .inner-wrap {
        padding: 5% 10% !important;
        width: 80%;
    }
    .section-one-title, .section-one-text {
        text-align: center;
    }
}
